export const MESSAGE_GROUP_LAST_MESSAGE_BY_GROUP_FRAGMENT = `
  fragment messageGroupLastMessageByGroupFragment on MessageGroup {
    uid
    active
    isSessionModerator: _isSessionModerator(myUid: "%authUser%")
    moderators: _moderators {
      uid
    }
    isMuted: _isMuted(myUid: "%authUser%")
    messages(orderBy: senttime_desc, first: 1, filter: { type: "REGULAR" }) {
        uid
        content
        senttime
        senttimestamp
    }
  }
`;
